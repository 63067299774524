import * as countryCodes from '../country/countryCodes';

import type { MarketingConsentConfig, MarketingConsentCountry } from './types';

// list of countries for what we know marketing consent rules
export const MARKETING_CONSENT_COUNTIES = [
  countryCodes.UNITED_STATES,
  countryCodes.CANADA,
  countryCodes.BELGIUM,
  countryCodes.NETHERLANDS,
  countryCodes.LUXEMBOURG,
  countryCodes.DENMARK,
  countryCodes.FINLAND,
  countryCodes.FRANCE,
  countryCodes.GERMANY,
  countryCodes.SWITZERLAND,
  countryCodes.AUSTRIA,
  countryCodes.HUNGARY,
  countryCodes.IRELAND,
  countryCodes.ITALY,
  countryCodes.NORWAY,
  countryCodes.POLAND,
  countryCodes.PORTUGAL,
  countryCodes.SPAIN,
  countryCodes.SWEDEN,
  countryCodes.UNITED_KINGDOM,
  countryCodes.ARGENTINA,
  countryCodes.BRAZIL,
  countryCodes.COLOMBIA,
  countryCodes.PERU,
  countryCodes.MEXICO,
  countryCodes.SOUTH_AFRICA,
  countryCodes.AUSTRALIA,
  countryCodes.NEW_ZEALAND,
  countryCodes.CHINA,
  countryCodes.HONG_KONG,
  countryCodes.INDIA,
  countryCodes.INDONESIA,
  countryCodes.MALAYSIA,
  countryCodes.BAHRAIN,
  countryCodes.EGYPT,
  countryCodes.IRAN,
  countryCodes.IRAQ,
  countryCodes.JORDAN,
  countryCodes.KUWAIT,
  countryCodes.LEBANON,
  countryCodes.OMAN,
  countryCodes.QATAR,
  countryCodes.SAUDI_ARABIA,
  countryCodes.SOUTH_SUDAN,
  countryCodes.SUDAN,
  countryCodes.SYRIAN_ARAB_REPUBLIC,
  countryCodes.UNITED_ARAB_EMIRATES,
  countryCodes.YEMEN,
  countryCodes.PHILIPPINES,
  countryCodes.SINGAPORE,
  countryCodes.KOREA,
  countryCodes.TAIWAN,
  countryCodes.THAILAND,
  countryCodes.VIETNAM,
  countryCodes.CHILE,
  countryCodes.URUGUAY,
] as const;

export const MARKETING_OPT_IN_CONFIG: MarketingConsentConfig = {
  emailMarketing: [
    countryCodes.CANADA,
    countryCodes.BELGIUM,
    countryCodes.NETHERLANDS,
    countryCodes.LUXEMBOURG,
    countryCodes.FINLAND,
    countryCodes.FRANCE,
    countryCodes.GERMANY,
    countryCodes.SWITZERLAND,
    countryCodes.AUSTRIA,
    countryCodes.HUNGARY,
    countryCodes.IRELAND,
    countryCodes.ITALY,
    countryCodes.DENMARK,
    countryCodes.NORWAY,
    countryCodes.POLAND,
    countryCodes.PORTUGAL,
    countryCodes.SPAIN,
    countryCodes.SWEDEN,
    countryCodes.UNITED_KINGDOM,
    countryCodes.ARGENTINA,
    countryCodes.BRAZIL,
    countryCodes.COLOMBIA,
    countryCodes.PERU,
    countryCodes.SOUTH_AFRICA,
    countryCodes.AUSTRALIA,
    countryCodes.NEW_ZEALAND,
    countryCodes.CHINA,
    countryCodes.HONG_KONG,
    countryCodes.INDIA,
    countryCodes.INDONESIA,
    countryCodes.MALAYSIA,
    countryCodes.BAHRAIN,
    countryCodes.EGYPT,
    countryCodes.IRAN,
    countryCodes.IRAQ,
    countryCodes.JORDAN,
    countryCodes.KUWAIT,
    countryCodes.LEBANON,
    countryCodes.OMAN,
    countryCodes.QATAR,
    countryCodes.SAUDI_ARABIA,
    countryCodes.SOUTH_SUDAN,
    countryCodes.SUDAN,
    countryCodes.SYRIAN_ARAB_REPUBLIC,
    countryCodes.UNITED_ARAB_EMIRATES,
    countryCodes.YEMEN,
    countryCodes.PHILIPPINES,
    countryCodes.SINGAPORE,
    countryCodes.KOREA,
    countryCodes.TAIWAN,
    countryCodes.THAILAND,
    countryCodes.VIETNAM,
  ],
  marketingProfiling: [
    countryCodes.BELGIUM,
    countryCodes.NETHERLANDS,
    countryCodes.LUXEMBOURG,
    countryCodes.ITALY,
    countryCodes.DENMARK,
    countryCodes.NORWAY,
    countryCodes.PORTUGAL,
    countryCodes.SPAIN,
    countryCodes.INDIA,
    countryCodes.BAHRAIN,
    countryCodes.EGYPT,
    countryCodes.IRAN,
    countryCodes.IRAQ,
    countryCodes.JORDAN,
    countryCodes.KUWAIT,
    countryCodes.LEBANON,
    countryCodes.OMAN,
    countryCodes.QATAR,
    countryCodes.SAUDI_ARABIA,
    countryCodes.SOUTH_SUDAN,
    countryCodes.SUDAN,
    countryCodes.SYRIAN_ARAB_REPUBLIC,
    countryCodes.UNITED_ARAB_EMIRATES,
    countryCodes.YEMEN,
  ],
  advertisingProfiling: [
    countryCodes.BELGIUM,
    countryCodes.NETHERLANDS,
    countryCodes.LUXEMBOURG,
    countryCodes.GERMANY,
    countryCodes.SWITZERLAND,
    countryCodes.AUSTRIA,
    countryCodes.HUNGARY,
    countryCodes.DENMARK,
    countryCodes.NORWAY,
    countryCodes.PORTUGAL,
    countryCodes.BRAZIL,
    countryCodes.CHINA,
    countryCodes.INDIA,
    countryCodes.KOREA,
    countryCodes.TAIWAN,
  ],
  analyticsProfiling: [
    countryCodes.NORWAY,
    countryCodes.DENMARK,
    countryCodes.ARGENTINA,
    countryCodes.INDIA,
    countryCodes.INDONESIA,
  ],
  dataSharing: [
    countryCodes.CANADA,
    countryCodes.BELGIUM,
    countryCodes.NETHERLANDS,
    countryCodes.LUXEMBOURG,
    countryCodes.FRANCE,
    countryCodes.GERMANY,
    countryCodes.SWITZERLAND,
    countryCodes.AUSTRIA,
    countryCodes.HUNGARY,
    countryCodes.ITALY,
    countryCodes.DENMARK,
    countryCodes.NORWAY,
    countryCodes.POLAND,
    countryCodes.PORTUGAL,
    countryCodes.SPAIN,
    countryCodes.ARGENTINA,
    countryCodes.MEXICO,
    countryCodes.AUSTRALIA,
    countryCodes.NEW_ZEALAND,
    countryCodes.CHINA,
    countryCodes.INDIA,
    countryCodes.PHILIPPINES,
    countryCodes.TAIWAN,
  ],
};

export const REQUIRED_MARKETING_OPT_IN_CONFIG = {
  emailMarketing: [countryCodes.SPAIN],
};

export const MARKETING_OPT_OUT_CONFIG: Partial<MarketingConsentConfig> = {
  emailMarketing: [countryCodes.MEXICO],
};

export const NO_IP_LOCATION_BASED_MARKETING: MarketingConsentCountry[] = [
  countryCodes.HONG_KONG,
  countryCodes.INDIA,
  countryCodes.MALAYSIA,
  countryCodes.SINGAPORE,
];

export const MARKETING_DOUBLE_OPT_IN_CONFIG: Partial<MarketingConsentConfig> = {
  emailMarketing: [
    countryCodes.LUXEMBOURG,
    countryCodes.GERMANY,
    countryCodes.SWITZERLAND,
    countryCodes.AUSTRIA,
    countryCodes.ITALY,
    countryCodes.PORTUGAL,
    countryCodes.SWEDEN,
    countryCodes.COLOMBIA,
    countryCodes.PERU,
  ],
};

// diff between full list of marketing consent countries and countries in configs
// to identify countries that does not require to consent to anything
export const MARKETING_CONSENT_COUNTRIES_WITHOUT_OPT_IN: MarketingConsentCountry[] =
  (() => {
    // Unique countryCodes from configs
    const countriesInConfigs = new Set([
      ...Object.values(MARKETING_OPT_IN_CONFIG).flatMap((array) => array),
      ...Object.values(MARKETING_OPT_OUT_CONFIG).flatMap((array) => array),
    ]);

    return MARKETING_CONSENT_COUNTIES.filter(
      (countryCode) => !countriesInConfigs.has(countryCode)
    );
  })();

export const PRERELEASE_BLOCKED_COUNTRIES = [
  countryCodes.SOUTH_AFRICA, // ready to unblock?

  // Middle East
  countryCodes.UNITED_ARAB_EMIRATES,
  countryCodes.BAHRAIN,
  countryCodes.EGYPT,
  countryCodes.IRAQ,
  countryCodes.IRAN,
  countryCodes.JORDAN,
  countryCodes.KUWAIT,
  countryCodes.LEBANON,
  countryCodes.OMAN,
  countryCodes.QATAR,
  countryCodes.SAUDI_ARABIA,
  countryCodes.SUDAN,
  countryCodes.SOUTH_SUDAN,
  countryCodes.SYRIAN_ARAB_REPUBLIC,
  countryCodes.YEMEN,

  countryCodes.CHINA,
  countryCodes.INDIA,

  // Benelux
  countryCodes.BELGIUM,
  countryCodes.NETHERLANDS,
  countryCodes.LUXEMBOURG,

  countryCodes.DENMARK,

  // GSA
  countryCodes.GERMANY,
  countryCodes.SWITZERLAND,
  countryCodes.AUSTRIA,

  countryCodes.HUNGARY,

  countryCodes.SPAIN, // ready to unblock?

  countryCodes.NORWAY,

  countryCodes.PORTUGAL,
];
